import axios from "axios"
import type { App } from 'vue'
import { showToast, showFailToast } from 'vant'
import 'vant/es/toast/style';

interface ApiResponse<T> {
    code: number,
    msg: string,
    data: T
}

const dev = false
const getHost = () => {
    return dev ? 'http://prerel.easycomfort.cn' : 'https://backend.easycomfort.cn'
}

const createAxios = (app: App) => {

    const service = axios.create({
        baseURL: getHost(),
        timeout: 10 * 1000,
        // withCredentials: true,
        // headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })

    service.interceptors.request.use(config => {
        // console.log('http interceptors ', config)

        if (config.headers) {
        }
        return config

    }, error => {
        return Promise.reject(error);
    })

    service.interceptors.response.use(resp => {
        // 2xx 范围内的状态码都会触发该函数。
        console.log('http response ', resp)

        if (resp.data?.code == 0 || resp.data?.hasOwnProperty('code')) {
            return resp.data
        }


        showToast(resp.data?.msg || '数据异常')

        return Promise.reject(new Error('Data Error'));

    }, err => {
        //超出 2xx 范围的状态码都会触发该函数。
        httpErrorHandle(err, app)
        return Promise.reject(err);
    })

    return service
}

function httpErrorHandle(error: any, app: App) {
    console.log('httpError', error)
    if (error && error.response) {

        showFailToast(error.message);
    }
}

function notify(app: App, msg: string) {
    app.config.globalProperties.$notify.error(msg)
    // app.config.globalProperties.$message.error(error.message)
    // app.config.globalProperties.$confirm(error.message)
    // app.config.globalProperties.$prompt(error.message)
    // app.config.globalProperties.$loading(error.message)
}


export {
    createAxios,
    getHost
}