<template>
  <router-view />
</template>

<script lang="ts" setup>

  

</script>
<style lang="scss" scoped>
</style>

