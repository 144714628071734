import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { findRouteByName, staticRoutes } from '@/router/static'

// const routes = findRouteByName('share-product')

// const routes = findRouteByName('share-365music')

const router = createRouter({
  history: createWebHashHistory(),
  routes: staticRoutes
})

// router.beforeEach((to, from, next) => {

//   next()
// })

router.afterEach((to, from) => {
  document.body.style.backgroundColor = (to.meta.color || '#eee') as string
  document.title = (to.meta.title || '糖易康') as string
})


export default router
