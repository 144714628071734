
import type { App } from 'vue'
import { createAxios } from './axios'

const registerIcons = (app: App) => {

    // for (const [name, T] of Object.entries(ElementIcons)) {
    //     app.component(name, T)
    // }
}

const registerProperties = (app: App) => {
    const proxys: any = {
        axios: createAxios(app)
    }
    Object.keys(proxys).forEach(key => {
        app.config.globalProperties[`$${key}`] = proxys[key]
    })
    // console.log('globalProperties', app.config.globalProperties)
}

const initAppContext = (app: App) => {
    registerIcons(app)
    registerProperties(app)
}

export {
    initAppContext,
    registerIcons,
    registerProperties
}

