import { createApp } from 'vue'
import Application from './App.vue'
import router from './router'

import VantPlus from 'vant'
import 'vant/lib/index.css';
// import '@/styles/index.scss'

import { initAppContext } from '@/utils/app'

const App = createApp(Application)
App.use(router).use(VantPlus)

initAppContext(App)

App.mount('#app')
