import { RouteRecordRaw } from 'vue-router'

const pageTitle = (name: string): string => {
    return `pagesTitle.${name}`
}

/*
 * 静态路由
 */
const staticRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
    },
    {
        // path: '/app/shareproduct/:share_id',
        path: '/app/shareproduct',
        name: 'share-product',
        component: () => import('@/views/share/product.vue'),
        meta: { title: '商品' }
    },
    {
        path: '/app/share365music',
        name: 'share-365music',
        component: () => import('@/views/share/365music.vue'),
        meta: { title: '音乐疗法' }
    },
    {
        path: '/app/agentcode',
        name: 'share-agentcode',
        component: () => import('@/views/share/agentCode.vue'),
        meta: { color: '#fff', title: '联盟商卡' }
    },
]


const findRouteByName = (routeName: string, root: boolean = true): Array<RouteRecordRaw> => {
    const route = staticRoutes.find(e => e.name == routeName) as RouteRecordRaw;
    if (root) {
        route.path = '/'
    }
    return [route]
}


export { staticRoutes, findRouteByName }
